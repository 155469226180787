import { useCallback, useContext, useEffect, useState } from 'react'
import { MdPerson, MdPowerSettingsNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/auth-context'
import { imgProfileUrl, serverAPI, tokenIsFalse } from '../../../utils/API'

const Navbar = ({ toggleDrawer }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [loggedInKaryawan, setLoggedInKaryawan] = useState({
    nmLengkap: '',
    foto: '',
  })

  const authCtx = useContext(AuthContext)

  const navigate = useNavigate()

  const fetchKaryawan = useCallback(() => {
    const options = {
      headers: { Authorization: `Bearer ${authCtx.loginToken}` }
    }

    serverAPI.get(`/karyawan/${authCtx.loggedInUserId}`, options)
      .then(response => {
        const resBody = response.data
        const resMsg = resBody.message
        // auto logout when response msg is token error
        if (resBody.result === false && tokenIsFalse(resMsg))
          return authCtx.logout()

        const dataKaryawan = resBody.data.karyawan
        setLoggedInKaryawan(prev => ({
          ...prev,
          nmLengkap: dataKaryawan.nm_lengkap,
          foto: dataKaryawan.foto
        }))
      })
  }, [authCtx])

  useEffect(() => {
    fetchKaryawan()
  }, [fetchKaryawan])

  const toggleDropdownHandler = () => setDropdownOpened(!dropdownOpened)
  const blurDropdownHandler = () => setDropdownOpened(false)

  const onClickProfileHandler = () => {
    navigate(`/karyawan/${authCtx.loggedInUserId}`)
    blurDropdownHandler()
  }

  const onLogoutHandler = () => {
    authCtx.logout()
    blurDropdownHandler()
  }

  return (
    <div className="sticky top-0 z-10">
      <div className="flex justify-between items-center h-16 py-3 px-4 bg-neutral-100">
        <button onClick={ toggleDrawer }>
          <img src="/images/icons/custom-burger.svg" alt="toggle side menu" className="w-7 cursor-pointer" />
        </button>

        <div className="flex flex-col">
          <button className="flex justify-center items-center h-16 gap-2 text-center text-sm font-medium text-black-500" onClick={ toggleDropdownHandler } onBlur={ blurDropdownHandler }>
            <img src={ loggedInKaryawan.foto ? `${imgProfileUrl}/${loggedInKaryawan.foto}` : "/images/profile/default.png" } alt="" className="rounded-full w-8 h-8 object-scale-down" />
            { loggedInKaryawan.nmLengkap }
          </button>

        </div>
      </div>
      <div className={ `absolute ${!dropdownOpened && 'hidden'} bg-neutral-100 mt-1 right-1 rounded-10 overflow-hidden w-32 text-sm drop-shadow-md` }>
        <ul>
          <li className="text-black-500 cursor-pointer hover:bg-black-100">
            <button className="flex gap-2 px-4 py-2" onMouseDown={ onClickProfileHandler }>
              <MdPerson className="w-5 h-5" />
              Profile
            </button>
          </li>
          <li className="text-danger-600 cursor-pointer hover:bg-black-100">
            <button className="flex gap-2 px-4 py-2" onMouseDown={ onLogoutHandler }>
              <MdPowerSettingsNew className="w-5 h-5" />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
