import { Outlet } from "react-router-dom"

const OnboardLayout = () => {
  return (
    <div className="lg:container flex justify-center items-center h-screen mx-4 lg:mx-auto">
      <div className="flex bg-neutral-100 rounded-[30px] shadow-md overflow-hidden w-full lg:w-7/12 h-max sm:max-w-sm lg:max-w-none">
        <div className="hidden lg:flex justify-center items-center bg-primary-600 p-12">
          <img src="/images/logo/logo-white-no-bg.svg" alt="Logo" className="w-[242px]" />
        </div>
        <div className="flex flex-col gap-6 justify-center w-full px-4 py-8 lg:px-8 lg:py-10">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default OnboardLayout
